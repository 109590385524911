import axios from "axios";
import React, { useState, useContext } from "react";
import { servicesSchema } from "./ServicesSchema";
import { useFormik } from "formik";
import "./AddService.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";
const AddService = () => {
  let {
    baseURL,
    setAddServies,
    setAddServicesRes,
    setAddServicesResMessage,
    getServices,
    setServices,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("icon", values.icon);
    formData.append("title_ar", values.title_ar);
    formData.append("title", values.title_ar);
    try {
      let { data } = await axios.post(`${baseURL}/service`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setAddServicesRes(data);
      if (data.success) {
        setAddServies(false);
        setLoading(false);
        setAddServicesResMessage(true);
        getServices(setServices);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddServicesResMessage(false);
      } else {
        setLoading(false);
        setAddServicesResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddServicesResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues: {
      icon: null,
      title_ar: "",
      title: "",
    },
    validationSchema: servicesSchema,
    onSubmit,
  });
  return (
    <>
      <div id="addService">
        <div onClick={() => setAddServies(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>اضافة خدمة</p>
            <i
              onClick={() => setAddServies(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="icon">
                <i className="fa-solid fa-image"></i>اختار صورة
              </label>
              <input
                onChange={(e) => {
                  setFieldValue("icon", e.target.files[0]);
                }}
                className={`${errors.icon && touched.icon ? "inputError" : ""}`}
                type="file"
                name="icon"
                id="icon"
              />
              {errors.icon && touched.icon && (
                <p className="error">{errors.icon}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="title_ar">
                <i className="fa-solid fa-rectangle-list"></i>عنوان الخدمة
              </label>
              <input
                value={values.title_ar}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.title_ar && touched.title_ar ? "inputError" : ""
                }`}
                type="text"
                name="title_ar"
                id="title_ar"
              />
              {errors.title_ar && touched.title_ar && (
                <p className="error">{errors.title_ar}</p>
              )}
            </div>
            <input
              hidden
              value={values.title_ar}
              onChange={handleChange}
              type="text"
              name="title"
              id="title"
            />
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddService;
