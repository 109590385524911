import React, { useContext, useState } from "react";
import "./Slider.scss";
import { Context } from "../Store";
import Menu from "../Menu/Menu";
import axios from "axios";
import AddSliderImg from "./AddSliderImg/AddSliderImg";
import { toast } from "react-toastify";

const Slider = () => {
  let {
    baseURL,
    sliders,
    addSliders,
    setAddSliders,
    addSlidersRes,
    addSliderResMessage,
    getSliders,
    setSliders,
  } = useContext(Context);
  let [deleteResMessage, setDeleteResMessage] = useState(false);
  let [deleteSlidersRes, setDeleteSlidersRes] = useState("");

  let [loading, setLoading] = useState(false);
  let [currentImg, setCurrentImg] = useState("");
  const deleteImage = async (id) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/slider-delete`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setDeleteSlidersRes(data);
      setCurrentImg(id);
      if (data.success) {
        setDeleteResMessage(true);
        getSliders(setSliders);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
        setLoading(false);
      } else {
        setLoading(false);
        setDeleteResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  function checkImgURL(url) {
    if (typeof url !== "string") return false;
    return url.match(/(https?:\/\/.*\.(?:jpg|jpeg|gif|png|tiff|bmp))/i) != null;
  }
  function checkVideoURL(url) {
    if (typeof url !== "string") return false;
    return url.match(/(https?:\/\/.*\.(?:webm|mkv|flv|mp4))/i) != null;
  }

  const renderMedia = (url) => {
    if (checkImgURL(url)) return <img className="slide" src={url} alt="" />;
    else if (checkVideoURL(url))
      return (
        <video
          className="slide slide-video"
          src={url}
          autoPlay
          muted
          loop
        ></video>
      );
  };
  return (
    <>
      <div className="serverMessage">
        {deleteSlidersRes.success === false && deleteResMessage ? (
          <p className="alert-danger">حدث خطأ اثناء الحذف</p>
        ) : (
          ""
        )}
        {deleteSlidersRes.success && deleteResMessage ? (
          <p className="alert-success">تم الحذف بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <div className="serverMessage">
        {!addSlidersRes.success && addSliderResMessage ? (
          <p className="alert-danger">{addSlidersRes.message}</p>
        ) : (
          ""
        )}
        {addSlidersRes.success && addSliderResMessage ? (
          <p className="alert-success">تم اضافة ملف بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="slider">
        <Menu />
        <div className="containers">
          <div className="change">
            <button className="btnChange" onClick={() => setAddSliders(true)}>
              أضافة ملف
            </button>
          </div>
          <div className="items">
            {sliders.map((slider) => (
              <div className="item" key={slider.id}>
                {renderMedia(slider.image)}
                <div className="delete" onClick={() => deleteImage(slider.id)}>
                  {loading && currentImg === slider.id ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa-solid fa-trash"></i>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {addSliders ? <AddSliderImg /> : ""}
    </>
  );
};

export default Slider;
