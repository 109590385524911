import axios from "axios";
import React, { useState, createContext, useEffect } from "react";

const initialValues = {
  baseURL: "https://muhra.almaharh.com/api",
  changeTitlesResMessage: "",
  setChangeTitlesResMessage: (v) => {},
  changeTitlesRes: "",
  setChangeTitlesRes: (v) => {},
  editTitles: false,
  setEditTitles: (v) => {},
  activeTitle: window.location.pathname,
  setActiveTitle: (v) => {},
  forgetPasswordForm: false,
  setForgetPasswordForm: (v) => {},
  forgetPasswordData: "",
  setForgetPasswordData: (v) => {},
  tokenForm: false,
  setTokenForm: (v) => {},
  resetForm: false,
  setResetForm: (v) => {},
  sliders: [],
  setSliders: (v) => {},
  addSliders: false,
  setAddSliders: (v) => {},
  addSlidersRes: "",
  setAddSlidersRes: (v) => {},
  addSliderResMessage: false,
  setAddSliderResMessage: (v) => {},
  menu: "",
  setMenu: (v) => {},
  info: "",
  setInfo: (v) => {},
  services: [],
  setServices: (v) => {},
  addServices: false,
  setAddServies: (v) => {},
  addServicesRes: "",
  setAddServicesRes: (v) => {},
  works: [],
  setWorks: (v) => {},
  partners: [],
  setPartners: (v) => {},
  addPartners: false,
  setAddPartners: (v) => {},
  addPartnersResMessage: false,
  setAddPartnersResMessage: (v) => {},
  profile: [],
  setProfile: (v) => {},
};
export let Context = createContext(initialValues);
const ContextProvider = (props) => {
  let { baseURL, profile, logout } = props;

  //////////////get APIs//////////////////////
  let [changeTitlesResMessage, setChangeTitlesResMessage] = useState(
    initialValues.changeTitlesResMessage
  );
  let [changeTitlesRes, setChangeTitlesRes] = useState("");
  let [editTitles, setEditTitles] = useState(false);
  let [activeTitle, setActiveTitle] = useState(window.location.pathname);
  let [forgetPasswordForm, setForgetPasswordForm] = useState(false);
  let [forgetPasswordData, setForgetPasswordData] = useState("");
  let [tokenForm, setTokenForm] = useState(false);
  let [resetForm, setResetForm] = useState(false);
  let [sliders, setSliders] = useState([]);
  let [addSliders, setAddSliders] = useState(false);
  let [addSlidersRes, setAddSlidersRes] = useState("");
  let [addSliderResMessage, setAddSliderResMessage] = useState(false);
  let [menu, setMenu] = useState("");
  let [info, setInfo] = useState("");
  let [changeInfoRes, setChangeInfoRes] = useState("");
  let [changeInfoResMessage, setChangeInfoResMessage] = useState("");
  let [editInfo, setEditInfo] = useState(false);
  let [services, setServices] = useState([]);
  let [addServices, setAddServies] = useState(false);
  let [addServicesRes, setAddServicesRes] = useState("");
  let [addServicesResMessage, setAddServicesResMessage] = useState(false);
  let [works, setWorks] = useState([]);
  let [addWork, setAddWork] = useState(false);
  let [editWork, setEditWork] = useState(false);
  let [addWorkRes, setAddWorkRes] = useState("");
  let [addWorkResMessage, setAddWorkResMessage] = useState(false);
  let [partners, setPartners] = useState([]);
  let [currentWork, setCurrentWork] = useState("");
  let [addPartners, setAddPartners] = useState(false);
  let [addPartnersRes, setAddPartnersRes] = useState("");
  let [addPartnersResMessage, setAddPartnersResMessage] = useState(false);
  ////////////////////////
  useEffect(() => {
    setActiveTitle(window.location.pathname);
  }, [window.location.pathname]);

  ///////////////////////
  const getSliders = async (callback) => {
    let { data } = await axios.get(`${baseURL}/slider`);
    callback(data.data);
  };
  /////////////////
  const getMenu = async (callback) => {
    let { data } = await axios.get(`${baseURL}/get-menu`, {
      headers: {
        Accept: "application/json",
        lang: "ar",
      },
    });
    callback(data.data);
  };
  //////////////////////////////////
  const getInfo = async (callback) => {
    let { data } = await axios.get(`${baseURL}/information`);
    callback(data.data);
  };
  /////////////////
  const getServices = async (callback) => {
    let { data } = await axios.get(`${baseURL}/services`);
    callback(data.data);
  };
  ////////////////////////////
  const getWorks = async (callback) => {
    let { data } = await axios.get(`${baseURL}/works`);
    callback(data.data);
  };
  ////////////////////////
  const getPartners = async (callback) => {
    let { data } = await axios.get(`${baseURL}/clients`);
    callback(data.data);
  };

  ////////////////////////
  useEffect(() => {
    getSliders(setSliders);
    getMenu(setMenu);
    getInfo(setInfo);
    getServices(setServices);
    getWorks(setWorks);
    getPartners(setPartners);
    // if (localStorage.getItem("userToken")) {
    //   getProfile(setProfile);
    // }
  }, []);
  /////////////////////////////////

  return (
    <Context.Provider
      value={{
        baseURL,
        sliders,
        menu,
        info,
        services,
        works,
        partners,
        profile,
        setMenu,
        forgetPasswordForm,
        setForgetPasswordForm,
        tokenForm,
        setTokenForm,
        forgetPasswordData,
        setForgetPasswordData,
        resetForm,
        setResetForm,
        activeTitle,
        setActiveTitle,
        editTitles,
        setEditTitles,
        changeTitlesRes,
        setChangeTitlesRes,
        changeTitlesResMessage,
        setChangeTitlesResMessage,
        addSlidersRes,
        setAddSlidersRes,
        addSliderResMessage,
        setAddSliderResMessage,
        addSliders,
        setAddSliders,
        addServices,
        setAddServies,
        addServicesRes,
        setAddServicesRes,
        addServicesResMessage,
        setAddServicesResMessage,
        addPartners,
        setAddPartners,
        addPartnersRes,
        setAddPartnersRes,
        addPartnersResMessage,
        setAddPartnersResMessage,
        addWork,
        setAddWork,
        addWorkRes,
        setAddWorkRes,
        addWorkResMessage,
        setAddWorkResMessage,
        changeInfoRes,
        setChangeInfoRes,
        changeInfoResMessage,
        setChangeInfoResMessage,
        editInfo,
        setEditInfo,
        logout,
        getSliders,
        setSliders,
        getPartners,
        setPartners,
        getServices,
        setServices,
        getWorks,
        setWorks,
        editWork,
        setEditWork,
        currentWork,
        setCurrentWork,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default ContextProvider;
