import React, { useContext, useState } from "react";
import "./Services.scss";
import { Context } from "../Store";
import Menu from "../Menu/Menu";
import axios from "axios";
import AddService from "./AddService/AddService";
import { toast } from "react-toastify";
const Services = () => {
  let {
    baseURL,
    services,
    addServices,
    setAddServies,
    addServicesRes,
    addServicesResMessage,
    getServices,
    setServices,
  } = useContext(Context);
  let [deleteResMessage, setDeleteResMessage] = useState(false);
  let [deleteServicesRes, setDeleteServicesRes] = useState("");
  let [loading, setLoading] = useState(false);
  let [currentService, setCurrentService] = useState("");
  const deleteServices = async (id) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/service-delete`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setDeleteServicesRes(data);
      setCurrentService(id);
      if (data.success) {
        setDeleteResMessage(true);
        getServices(setServices);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
        setLoading(false);
      } else {
        setLoading(false);
        setDeleteResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  return (
    <>
      <div className="serverMessage">
        {deleteServicesRes.success === false && deleteResMessage ? (
          <p className="alert-danger">حدث خطأ اثناء الحذف</p>
        ) : (
          ""
        )}
        {deleteServicesRes.success && deleteResMessage ? (
          <p className="alert-success">تم الحذف بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <div className="serverMessage">
        {addServicesRes.success === false && addServicesResMessage ? (
          <p className="alert-danger">{addServicesRes.message}</p>
        ) : (
          ""
        )}
        {addServicesRes.success && addServicesResMessage ? (
          <p className="alert-success">تم اضافة الخدمة بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="services">
        <Menu />
        <div className="containers">
          <div className="change">
            <button className="btnChange" onClick={() => setAddServies(true)}>
              أضافة خدمة
            </button>
          </div>
          <div className="items">
            {services.map((service) => (
              <div className="item" key={service.id}>
                <img src={service.icon} alt="" />
                <p>{service.title}</p>
                <div
                  className="delete"
                  onClick={() => deleteServices(service.id)}
                >
                  {loading && currentService === service.id ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa-solid fa-trash"></i>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {addServices && <AddService />}
    </>
  );
};

export default Services;
