import React, { useContext } from 'react';
import './Info.scss';
import { Context } from '../Store';
import Menu from '../Menu/Menu';
import ChangeInfo from './ChangeInfo/ChangeInfo';

const Info = () => {
    // let{menu,editTitles,setEditTitles,changeTitlesResMessage,changeTitlesRes} = useContext(Context);
    let{info,editInfo, setEditInfo,changeInfoRes,changeInfoResMessage} = useContext(Context);

  return (
    <>
    <div className='serverMessage'>
    {changeInfoRes.success===false?<p className='alert-danger'>{changeInfoRes.message}</p>:""}
    {changeInfoRes.success &&changeInfoResMessage?<p className='alert-success'>تم التعديل بنجاح</p>:""}
    </div>
        <section id='info'>
            <Menu/>
            <div className="containers">
              <div className="change">
              <button className='btnChange'
               onClick={() => setEditInfo(true)}
               >تعديل المعلومات</button>
              </div>
              <div className="items">
                <div className="item">
                <h2>رقم الهاتف</h2>
                  <p>{info.phone}</p>
                </div>
                <div className="item">
                  <h2>الموقع الالكنروني</h2>
                  <p>{info.site}</p>
                </div>
              </div>
            </div>
        </section>
    {editInfo?<ChangeInfo/>:""}
    </>
  )
}

export default Info;