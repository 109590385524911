import React, { useContext, useState } from "react";
import "./Partners.scss";
import { Context } from "../Store";
import Menu from "../Menu/Menu";
import axios from "axios";
import AddPartner from "./AddPartner/AddPartner";
import { toast } from "react-toastify";
const Partners = () => {
  let {
    baseURL,
    partners,
    addPartners,
    setAddPartners,
    addPartnersRes,
    addPartnersResMessage,
    getPartners,
    setPartners,
  } = useContext(Context);
  let [deleteResMessage, setDeleteResMessage] = useState(false);
  let [deletePartnersRes, setDeletePartnersRes] = useState("");
  let [loading, setLoading] = useState(false);
  let [currentPartners, setCurrentPartners] = useState("");
  const deletePartners = async (id) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/client-delete`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setDeletePartnersRes(data);
      setCurrentPartners(id);
      if (data.success) {
        setDeleteResMessage(true);
        getPartners(setPartners);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
        setLoading(false);
      } else {
        setLoading(false);
        setDeleteResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  return (
    <>
      <div className="serverMessage">
        {deletePartnersRes.success === false && deleteResMessage ? (
          <p className="alert-danger">حدث خطأ اثناء الحذف</p>
        ) : (
          ""
        )}
        {deletePartnersRes.success && deleteResMessage ? (
          <p className="alert-success">تم الحذف بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <div className="serverMessage">
        {addPartnersRes.success === false && addPartnersResMessage ? (
          <p className="alert-danger">{addPartnersRes.message}</p>
        ) : (
          ""
        )}
        {addPartnersRes.success && addPartnersResMessage ? (
          <p className="alert-success">تم اضافة الشريك بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="partners">
        <Menu />
        <div className="containers">
          <div className="change">
            <button className="btnChange" onClick={() => setAddPartners(true)}>
              أضافة شريك
            </button>
          </div>
          <div className="items">
            {partners.map((partner) => (
              <div className="item" key={partner.id}>
                <img src={partner.image} alt="" />
                <p>{partner.name}</p>
                <div
                  className="delete"
                  onClick={() => deletePartners(partner.id)}
                >
                  {loading && currentPartners === partner.id ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa-solid fa-trash"></i>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {addPartners ? <AddPartner /> : ""}
    </>
  );
};

export default Partners;
