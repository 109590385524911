import React, { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import ContextProvider from "./components/Store";
import "./App.scss";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Slider from "./components/Slider/Slider";
import Services from "./components/Services/Services";
import Partners from "./components/Partners/Partners";
import Works from "./components/Works/Works";
import Info from "./components/Info/Info";
import Profile from "./components/Profile/Profile";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import { ToastContainer } from "react-toastify";
import Notfound from "./components/Notfound/Notfound";

const App = () => {
  let [profile, setProfile] = useState("");
  const baseURL = "https://muhra.almaharh.com/api";
  let navigate = useNavigate();
  const logout = async () => {
    try {
      await axios.get(`${baseURL}/admin-logout`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      localStorage.removeItem("userToken");
      return navigate("/login");
    } catch (error) {
      localStorage.removeItem("userToken");
      navigate("/login");
    }
  };

  // let[logged,setLogged]=useState(false);
  const getProfile = async (callback) => {
    try {
      let { data } = await axios.get(`${baseURL}/admin-profile`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      return callback(data.data);
    } catch (error) {
      logout();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      getProfile(setProfile);
    }
  }, [localStorage.getItem("userToken")]);

  const ProtectedRoute = (props) => {
    if (localStorage.getItem("userToken") === null) {
      return <Navigate to="/login" />;
    } else {
      return props.children;
    }
  };
  return (
    <>
      <ContextProvider baseURL={baseURL} profile={profile} logout={logout}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/slider"
            element={
              <ProtectedRoute>
                <Slider />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/services"
            element={
              <ProtectedRoute>
                <Services />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/partners"
            element={
              <ProtectedRoute>
                <Partners />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/works"
            element={
              <ProtectedRoute>
                <Works />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/info"
            element={
              <ProtectedRoute>
                <Info />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="*" element={<Notfound />}></Route>
        </Routes>

        {/* <CustomModal
          closeIcon="ss"
          setModalOpened={"setModalOpened"}
          modalTitle="ss"
          className="add-service-modal"
        ></CustomModal> */}

        <ToastContainer />
      </ContextProvider>
    </>
  );
};

export default App;
