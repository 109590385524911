import * as yup from "yup";
// const passwordRules = /^(?=.*\d) (?=.*[a-z]) (?=.*[A-Z]).{5,}$/;
// min 5 Characters, 1 upper case letter, 1 lower case letter, 1 numeric digit
export const sliderSchema = yup.object().shape({
  image: yup
    .mixed()
    .required("برجاء اختيار ملف")
    .test(
      "type",
      "برجاء اختيار ملف بصيغة jpeg أو jpg أو png أو mp4",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/jpg" ||
            value.type === "image/png" ||
            value.type === "video/mp4")
        );
      }
    )
    .test("size", "not a valid size", (value) => {
      return value && value?.size < 10 * 1024 * 1024;
    }),
});
