import axios from "axios";
import React, { useState, useContext } from "react";
import "./ResetPassword.scss";
import { resetPasswordSchema } from "./ResetPasswordSchema";
import { Context } from "../../Store";
import { useFormik } from "formik";
import { toast } from "react-toastify";
const ResetPassword = () => {
  let { baseURL, forgetPasswordData, setResetForm } = useContext(Context);
  let [loading, setLoading] = useState(false);
  let [successMessage, setSuccessMessage] = useState(false);
  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/reset-admin-password`,
        values,
        {
          headers: {
            Accept: "application/json",
            lang: "ar",
          },
        }
      );
      if (data.success) {
        actions.resetForm();
        setSuccessMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setSuccessMessage(false);
        setResetForm(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: forgetPasswordData.data.email,
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit,
  });
  return (
    <>
      <div className="serverMessage">
        {successMessage ? (
          <p className="alert-success">تم اعادة تعيين كلمة المرور بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <div id="resetPassword">
        <div className="layar"></div>
        <div className="container">
          <div className="head">
            <p>أعادة تعيين كلمة السر</p>
            <i
              onClick={() => setResetForm(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="email">
                <i className="fa-solid fa-envelope"></i> البريد الالكترونى
              </label>
              <input
                disabled
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.email && touched.email ? "inputError" : ""
                }`}
                type="email"
                name="email"
                id="resetEmail"
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="password">
                <i className="fa-solid fa-lock"></i>كلمة المرور الجديدة
              </label>
              <input
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.password && touched.password ? "inputError" : ""
                }`}
                type="password"
                name="password"
                id="password"
              />
              {errors.password && touched.password && (
                <p className="error">{errors.password}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="password_confirmation">
                <i className="fa-solid fa-lock"></i>أعد ادخال كلمة المرور
                الجديدة
              </label>
              <input
                value={values.password_confirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.password_confirmation && touched.password_confirmation
                    ? "inputError"
                    : ""
                }`}
                type="password"
                name="password_confirmation"
                id="password_confirmation"
              />
              {errors.password_confirmation &&
                touched.password_confirmation && (
                  <p className="error">{errors.password_confirmation}</p>
                )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "أعادة التعيين"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
