import axios from "axios";
import React, { useState, useContext } from "react";
import "./ForgetPassword.scss";
import { forgetPasswordSchema } from "./ForgetPasswordSchema";
import { Context } from "../../Store";
import { useFormik } from "formik";
import { toast } from "react-toastify";
const ForgetPassword = () => {
  let {
    baseURL,
    setForgetPasswordForm,
    setTokenForm,
    forgetPasswordData,
    setForgetPasswordData,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/enter-admin-email-reset`,
        values,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setForgetPasswordData(data);
      if (data.success) {
        actions.resetForm();
        setForgetPasswordForm(false);
        setTokenForm(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit,
  });
  return (
    <>
      <div className="serverMessage">
        {forgetPasswordData.success === false ? (
          <p className="alert-danger">{forgetPasswordData.message}</p>
        ) : (
          ""
        )}
      </div>
      <div id="forgetPassword">
        <div
          onClick={() => setForgetPasswordForm(false)}
          className="layar"
        ></div>
        <div className="container">
          <div className="head">
            <p>هل نسيت كلمة المرور ؟</p>
            <i
              onClick={() => setForgetPasswordForm(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="email">
                <i className="fa-solid fa-envelope"></i> البريد الالكترونى
              </label>
              <input
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.email && touched.email ? "inputError" : ""
                }`}
                type="email"
                name="email"
                id="email"
                placeholder="أدخل البريد الالكترونى"
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "أرسل"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
