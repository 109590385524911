import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { loginSchema } from "./LoginSchema";
import logo from "./../../imgs/logo.png";
import { useFormik } from "formik";
import { Context } from "../Store";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import Token from "./Token/Token";
import ResetPassword from "./ResetPassword/ResetPassword";
import { toast } from "react-toastify";

const Login = () => {
  let { baseURL, forgetPasswordForm, tokenForm, resetForm } =
    useContext(Context);
  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/");
  };
  let [loginData, setLoginData] = useState("");
  let [loading, setLoading] = useState(false);
  let [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      let { data } = await axios.post(`${baseURL}/admin-login`, values, {
        headers: {
          Accept: "application/json",
          lang: "ar",
        },
      });
      setLoginData(data);
      if (data.success) {
        localStorage.setItem("userToken", data.data.token);
        // call safeUserData from App.jsx
        await new Promise((resolve) => setTimeout(resolve, 2000));
        actions.resetForm();
        goToHome();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });
  useEffect(() => {
    if (forgetPasswordForm || tokenForm || resetForm) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [forgetPasswordForm, tokenForm, resetForm]);

  return (
    <>
      <div className="serverMessage">
        {loginData.success === false ? (
          <p className="alert-danger">{loginData.message}</p>
        ) : (
          ""
        )}
        {loginData.success ? (
          <p className="alert-success">تم تسجيل الدخول بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="login">
        <img src={logo} alt="" />
        <div className="container">
          <div className="formHeader">
            <h2>تسجيل الدخول</h2>
            <p>سجل دخولك الان للوحة التحكم الخاصة بك</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="email">
                <i className="fa-solid fa-user"></i> البريد الالكترونى
              </label>
              <input
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.email && touched.email ? "inputError" : ""
                }`}
                type="email"
                name="email"
                id="email"
                placeholder="أدخل البريد الالكترونى"
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="password">
                <i className="fa-solid fa-lock"></i> كلمة المرور{" "}
              </label>
              <input
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.password && touched.password ? "inputError" : ""
                }`}
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="أدخل كلمة المرور "
              />
              {showPassword ? (
                <i
                  className="fa-solid fa-eye showPassword"
                  onClick={() => setShowPassword(false)}
                ></i>
              ) : (
                <i
                  className="fa-solid fa-eye-slash showPassword"
                  onClick={() => setShowPassword(true)}
                ></i>
              )}

              {errors.password && touched.password && (
                <p className="error">{errors.password}</p>
              )}
            </div>
            {/* <p
              onClick={() => setForgetPasswordForm(true)}
              className="forgetPassword"
            >
              هل نسيت كلمة المرور ؟
            </p> */}
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "تسجيل الدخول"
              )}
            </button>
          </form>
        </div>
      </section>
      {forgetPasswordForm ? <ForgetPassword /> : ""}
      {tokenForm ? <Token /> : ""}
      {resetForm ? <ResetPassword /> : ""}
    </>
  );
};

export default Login;
