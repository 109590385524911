import axios from "axios";
import React, { useState, useContext } from "react";
import { sliderSchema } from "./SliderSchema";
import { useFormik } from "formik";
import "./AddSliderImg.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";
const AddSliderImg = () => {
  let {
    baseURL,
    setAddSliders,
    setAddSlidersRes,
    setAddSliderResMessage,
    getSliders,
    setSliders,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", values.image);

    try {
      let { data } = await axios.post(`${baseURL}/save-slider`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setLoading(false);
      setAddSlidersRes(data);
      if (data.success) {
        setAddSliders(false);
        getSliders(setSliders);
        setAddSliderResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddSliderResMessage(false);
      } else {
        setAddSliderResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddSliderResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const { errors, touched, isSubmitting, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        image: null,
      },
      validationSchema: sliderSchema,
      onSubmit,
    });

  return (
    <>
      <div id="addSliderImg">
        <div onClick={() => setAddSliders(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>اضافة ملف</p>
            <i
              onClick={() => setAddSliders(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="image">
                <i className="fa-solid fa-image"></i>اختار ملف
              </label>
              <input
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0]);
                }}
                className={`${
                  errors.image && touched.image ? "inputError" : ""
                }`}
                type="file"
                name="image"
                id="image"
              />
              {errors.image && touched.image && (
                <p className="error">{errors.image}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSliderImg;
