import axios from "axios";
import React, { useState, useContext } from "react";
import { infoSchema } from "./InfoSchema";
import { useFormik } from "formik";
import "./ChangeInfo.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";
const ChangeInfo = () => {
  let {
    baseURL,
    info,
    setEditInfo,
    setChangeInfoResMessage,
    setChangeInfoRes,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let { data } = await axios.post(`${baseURL}/information`, values, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setChangeInfoRes(data);
      if (data.success) {
        setEditInfo(false);
        setLoading(false);
        setChangeInfoResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeInfoResMessage(false);
      } else {
        setLoading(false);
        setChangeInfoResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeInfoResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      address: "المملكة العربية السعودية . الرياض\nالمنطقة التالتة .",
      email: "info@floweration.sa",
      lat: "30.094479469761783",
      lng: "31.33892068915551",
      facebook: "www.facebook.com",
      twitter: "www.twitter.com",
      linkedin: "www.linkedin.com",
      instagram: "www.instagram.com",
      description:
        "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق. إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.",

      phone: info.phone,
      site: info.site,
    },
    validationSchema: infoSchema,
    onSubmit,
  });

  return (
    <>
      <div id="changeInfo">
        <div onClick={() => setEditInfo(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>تعديل المعلومات</p>
            <i
              onClick={() => setEditInfo(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="phone">
                <i className="fa-solid fa-rectangle-list"></i>رقم الهاتف
              </label>
              <input
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.phone && touched.phone ? "inputError" : ""
                }`}
                type="number"
                name="phone"
                id="phone"
              />
              {errors.phone && touched.phone && (
                <p className="error">{errors.phone}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="site">
                <i className="fa-solid fa-rectangle-list"></i>الموقع الالكتروني
              </label>
              <input
                value={values.site}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.site && touched.site ? "inputError" : ""}`}
                type="text"
                name="site"
                id="site"
              />
              {errors.site && touched.site && (
                <p className="error">{errors.site}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangeInfo;
