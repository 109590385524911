import axios from "axios";
import React, { useState, useContext } from "react";
import { partnersSchema } from "./PartnersSchema";
import { useFormik } from "formik";
import "./AddPartner.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";
const AddPartner = () => {
  let {
    baseURL,
    setAddPartners,
    setAddPartnersRes,
    setAddPartnersResMessage,
    getPartners,
    setPartners,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", values.image);
    formData.append("name", values.name);
    try {
      let { data } = await axios.post(`${baseURL}/client`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setAddPartnersRes(data);
      if (data.success) {
        setAddPartners(false);
        setLoading(false);
        setAddPartnersResMessage(true);
        getPartners(setPartners);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddPartnersResMessage(false);
      } else {
        setLoading(false);
        setAddPartnersResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddPartnersResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
  } = useFormik({
    initialValues: {
      image: null,
      name: "",
    },
    validationSchema: partnersSchema,
    onSubmit,
  });
  return (
    <>
      <div id="addPartner">
        <div onClick={() => setAddPartners(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>اضافة شريك</p>
            <i
              onClick={() => setAddPartners(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="image">
                <i className="fa-solid fa-image"></i>اختار صورة
              </label>
              <input
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0]);
                }}
                className={`${
                  errors.image && touched.image ? "inputError" : ""
                }`}
                type="file"
                name="image"
                id="image"
              />
              {errors.image && touched.image && (
                <p className="error">{errors.image}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="name">
                <i className="fa-solid fa-rectangle-list"></i>أسم الشريك
              </label>
              <input
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.name && touched.name ? "inputError" : ""}`}
                type="text"
                name="name"
                id="name"
              />
              {errors.name && touched.name && (
                <p className="error">{errors.name}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPartner;
