import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import './Menu.scss';
import logo from "./../../imgs/logo.png";
import { Context } from '../Store';
const Menu = () => {
    let{activeTitle,profile,logout} = useContext(Context);
    let [navDropBox, setNavDropBox] = useState(false)
    const handleNavDropBox = () => {
        !navDropBox?setNavDropBox(true):setNavDropBox(false);
    }
  return (
   <>
   <section id='menu' >
    <div className='container'>
        <div className="sideBar">
            <div className='logo'>
                <img src={logo} alt="" />
            </div>
            <div className='items'>
                <div className={activeTitle==="/"?"item active":"item"}>
                    <Link  className='link' to="/" ><h2 className={activeTitle==="/"?"activeLink":""}>العناوين</h2></Link>
                </div>
                <div className={activeTitle==="/slider"?"item active":"item"}>
                    <Link  className='link' to="/slider"><h2 className={activeTitle==="/slider"?"activeLink":""}>Slider</h2></Link>
                </div>
                <div className={activeTitle==="/services"?"item active":"item"}>
                    <Link  className='link' to="/services"><h2 className={activeTitle==="/services"?"activeLink":""}>الخدمات</h2></Link>
                </div>
                <div className={activeTitle==="/partners"?"item active":"item"}>
                    <Link  className='link' to="/partners"><h2 className={activeTitle==="/partners"?"activeLink":""}>الشركاء</h2></Link>
                </div>
                <div className={activeTitle==="/works"?"item active":"item"}>
                    <Link  className='link' to="/works"><h2 className={activeTitle==="/works"?"activeLink":""}>الأعمال</h2></Link>
                </div>
                <div className={activeTitle==="/info"?"item active":"item"}>
                    <Link  className='link' to="/info" ><h2 className={activeTitle==="/info"?"activeLink":""}>المعلومات</h2></Link>
                </div>
            </div>
        </div>
        <div className="bar" onClick={handleNavDropBox}>
            <div className="item">
                <img src={profile?.image} alt="" />
                <i className="fa-solid fa-caret-down"></i>
            </div>
            {navDropBox?
             <div className='dropBox'>
             <ul>
                 <li>
                     
                     <Link className='dropItem' to="/profile"> <i className="fa-solid fa-user"></i> الملف الشخصي</Link>
                 </li>
                 <li>
                     
                     <Link className='dropItem' to="/change-password"><i className="fa-solid fa-lock"></i> تغيير كلمة المرور</Link>
                 </li>
                 <li onClick={()=>logout()}> 
                             
                     <Link className='dropItem' to="/login"> <i className="fa-solid fa-right-from-bracket"></i>تسجيل الخروج</Link>
                 </li>
             </ul>
         </div>
         :""}
           
            
        </div>
        
    </div>
   </section>
   </>
    
  )
}

export default Menu;