import axios from "axios";
import React, { useState, useContext } from "react";
import { workSchema } from "../WorkSchema";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import "./AddWork.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";
const initialValues = {
  name_ar: "",
  name_en: "",
  name: "",
  number: "",
  areas: [
    {
      title: "",
      title_ar: "",
    },
  ],
};

const AddWork = () => {
  let {
    baseURL,
    setAddWork,
    setAddWorkRes,
    setAddWorkResMessage,
    getWorks,
    setWorks,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let { data } = await axios.post(`${baseURL}/work`, values, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setAddWorkRes(data);
      if (data.success) {
        setAddWork(false);
        setLoading(false);
        setAddWorkResMessage(true);
        getWorks(setWorks);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddWorkResMessage(false);
      } else {
        setLoading(false);
        setAddWorkResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAddWorkResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  // const { values , errors , touched ,isSubmitting, handleBlur , handleChange , handleSubmit } =
  // useFormik({
  //   // initialValues: {
  //   //   name_ar:"",
  //   //   name_en:"",
  //   //   name:"",
  //   //   number:"",
  //   //   areas:[{
  //   //     title:"",
  //   //     title_ar:"",
  //   //   }]

  //   // },
  //   validationSchema: workSchema,
  //   onSubmit,
  // });

  return (
    <>
      <div id="addWork">
        <div onClick={() => setAddWork(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>أضافة عمل</p>
            <i
              onClick={() => setAddWork(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={workSchema}
          >
            {({ values }) => {
              return (
                <Form className="form">
                  <div className="input">
                    <label htmlFor="name_ar">اسم العمل</label>
                    <Field id="name_ar" name="name_ar" />
                    <ErrorMessage
                      name={"name_ar"}
                      component="div"
                      className="inputError error"
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="number">رقم الاعمال</label>
                    <Field id="number" name="number" />
                    <ErrorMessage
                      name={"number"}
                      component="div"
                      className="inputError error"
                    />
                  </div>
                  <FieldArray name="areas">
                    {({ remove, push }) => (
                      <div className="area">
                        {values.areas.length > 0 &&
                          values.areas.map((area, index) => (
                            <div className="" key={index}>
                              <div className="input">
                                <label htmlFor={`areas.${index}.title`}>
                                  المنطقة
                                </label>
                                <Field
                                  name={`areas.${index}.title`}
                                  type="text"
                                  id="inputArea"
                                />
                                <ErrorMessage
                                  name={`areas.${index}.title`}
                                  component="div"
                                  className="inputError error"
                                />

                                <div className="col">
                                  <button
                                    type="button"
                                    className="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        <button
                          type="button"
                          className="addArea"
                          onClick={() => push({ title: "", title_ar: "" })}
                        >
                          أضافة منطقة
                        </button>
                      </div>
                    )}
                  </FieldArray>
                  <button disabled={loading} type="submit" className="loginBtn">
                    {loading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      "حفظ"
                    )}
                  </button>
                </Form>
              );
            }}
          </Formik>
          {/* <form onSubmit={handleSubmit} >
                <div className='input'>
                <label htmlFor="number"><i className="fa-solid fa-rectangle-list"></i>رقم الأعمال</label>
                <input
                   value={values.number}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   className={`${errors.number && touched.number? "inputError":""}`}
                   type="number" name="number" id="number" />
                  {errors.number && touched.number && <p className="error">{errors.number}</p>}
                </div>
                <div className='input'>
                <label htmlFor="name_ar"><i className="fa-solid fa-rectangle-list"></i>اسم العمل</label>
                <input
                   value={values.name_ar}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   className={`${errors.name_ar && touched.name_ar? "inputError":""}`}
                   type="text" name="name_ar" id="name_ar" />
                  {errors.name_ar && touched.name_ar && <p className="error">{errors.name_ar}</p>}
                </div>
                <input
                   hidden
                   value={values.name_ar}
                   onChange={handleChange}
                   type="text" name="name" id="name" />
                   <input
                   hidden
                   value={values.name_ar}
                   onChange={handleChange}
                   type="name_en" name="name_en" id="name_en" />
                <button disabled={isSubmitting||loading} type='submit' className='loginBtn'>
                {loading? <i className='fas fa-spinner fa-spin'></i>:"حفظ"}
                </button>
            </form> */}
        </div>
      </div>
    </>
  );
};

export default AddWork;
