import axios from "axios";
import React, { useState, useContext } from "react";
import { homeSchema } from "./HomeSchema";
import { useFormik } from "formik";
import "./ChangeTitles.scss";
import { Context } from "../../Store";
import { toast } from "react-toastify";

const ChangeTitles = () => {
  let {
    baseURL,
    menu,
    setMenu,
    setEditTitles,
    setChangeTitlesResMessage,
    setChangeTitlesRes,
  } = useContext(Context);
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let { data } = await axios.post(`${baseURL}/menu`, values, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
          lang: "ar",
        },
      });
      setChangeTitlesRes(data);
      if (data.success) {
        setMenu(data.data);
        setEditTitles(false);
        setLoading(false);
        setChangeTitlesResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeTitlesResMessage(false);
      } else {
        setLoading(false);
        setChangeTitlesResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeTitlesResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      about_us_ar: menu.about_us_ar,
      vision_ar: menu.vision_ar,
      message_ar: menu.message_ar,

      about_us: menu.about_us,
      vision: menu.vision,
      message: menu.message,

      about_us_en: menu.about_us_en,
      vision_en: menu.vision_en,
      message_en: menu.message_en,
    },
    validationSchema: homeSchema,
    onSubmit,
  });
  return (
    <>
      <div id="changeTitles">
        <div onClick={() => setEditTitles(false)} className="layar"></div>
        <div className="container">
          <div className="head">
            <p>تعديل العناوين</p>
            <i
              onClick={() => setEditTitles(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="about_us">
                <i className="fa-solid fa-rectangle-list"></i>نبذة عنا
              </label>
              <textarea
                value={values.about_us}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.about_us && touched.about_us ? "inputError" : ""
                }`}
                type="text"
                name="about_us"
                id="about_us"
              />
              {errors.about_us && touched.about_us && (
                <p className="error">{errors.about_us}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="vision">
                <i className="fa-solid fa-rectangle-list"></i>الرؤية
              </label>
              <textarea
                value={values.vision}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.vision && touched.vision ? "inputError" : ""
                }`}
                type="text"
                name="vision"
                id="vision"
              />
              {errors.vision && touched.vision && (
                <p className="error">{errors.vision}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="message">
                <i className="fa-solid fa-rectangle-list"></i>الرسالة
              </label>
              <textarea
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.message && touched.message ? "inputError" : ""
                }`}
                type="text"
                name="message"
                id="message"
              />
              {errors.message && touched.message && (
                <p className="error">{errors.message}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangeTitles;
