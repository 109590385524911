import axios from "axios";
import React, { useState, useContext } from "react";
import "./Token.scss";
import { tokenSchema } from "./TokenSchema";
import { Context } from "../../Store";
import { useFormik } from "formik";
import { toast } from "react-toastify";
const Token = () => {
  let { baseURL, setTokenForm, forgetPasswordData, setResetForm } =
    useContext(Context);
  let [tokenData, setTokenData] = useState("");
  let [loading, setLoading] = useState(false);
  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      let { data } = await axios.get(
        `${baseURL}/admin-find-token?token=${values.token}&email=${forgetPasswordData.data.email}`,
        {
          headers: {
            Accept: "application/json",
            lang: "ar",
          },
        }
      );
      setTokenData(data);
      if (data.success) {
        actions.resetForm();
        setTokenForm(false);
        setResetForm(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      token: "",
    },
    validationSchema: tokenSchema,
    onSubmit,
  });
  return (
    <>
      <div className="serverMessage">
        {tokenData.success === false ? (
          <p className="alert-danger">{tokenData.message}</p>
        ) : (
          ""
        )}
      </div>
      <div id="tokenForm">
        <div className="layar"></div>
        <div className="container">
          <div className="head">
            <p>تم أرسال كود التأكيد الي بريدك الالكترونى</p>
            <i
              onClick={() => setTokenForm(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="token">
                <i className="fa-solid fa-envelope"></i>كود التأكيد
              </label>
              <input
                value={values.token}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.token && touched.token ? "inputError" : ""
                }`}
                type="number"
                name="token"
                id="token"
                placeholder="أدخل كود التفعيل"
              />
              {errors.token && touched.token && (
                <p className="error">{errors.token}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "أرسل"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Token;
