import * as yup from "yup";
// const passwordRules = /^(?=.*\d) (?=.*[a-z]) (?=.*[A-Z]).{5,}$/;
// min 5 Characters, 1 upper case letter, 1 lower case letter, 1 numeric digit 
export const partnersSchema = yup.object().shape({
    name: yup.string().required("هذا الحقل مطلوب"),
    image: yup.mixed().required("برجاء اختيار ملف")
    // .test("type", "برجاء اختيار صورة بصيغة jpeg أو jpg أو png", (value) => {
    //     return value && (
    //         value[0].type === "image/jpeg" ||
    //         value[0].type === "image/jpg" ||
    //         value[0].type === "image/png" 
    //     );
    // }),
});