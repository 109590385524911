import axios from "axios";
import React, { useState, useContext } from "react";
import { changePasswordSchema } from "./ChangePasswordSchema";
import { useFormik } from "formik";
import Menu from "../Menu/Menu";
import "./ChangePassword.scss";
import { Context } from "../Store";
import { toast } from "react-toastify";
const ChangePassword = () => {
  let { baseURL } = useContext(Context);
  let [loading, setLoading] = useState(false);
  let [changePasswordRes, setChangePasswordRes] = useState("");
  let [changePasswordResMessage, setChangePasswordResMessage] = useState(false);

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/change-admin-password`,
        values,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setChangePasswordRes(data);
      if (data.success) {
        actions.resetForm();
        setLoading(false);
        setChangePasswordResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangePasswordResMessage(false);
      } else {
        setLoading(false);
        setChangePasswordResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangePasswordResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit,
  });
  return (
    <>
      <div className="serverMessage">
        {changePasswordRes.success === false ? (
          <p className="alert-danger">{changePasswordRes.message}</p>
        ) : (
          ""
        )}
        {changePasswordRes.success && changePasswordResMessage ? (
          <p className="alert-success">تم التعديل بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="changePassword">
        <Menu />
        <div className="containers">
          <div className="head">
            <h3>تغيير كلمة المرور</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="old_password">
                <i className="fa-solid fa-lock"></i>كلمة المرور القديمة
              </label>
              <input
                value={values.old_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.old_password && touched.old_password
                    ? "inputError"
                    : ""
                }`}
                type="password"
                name="old_password"
                id="old_password"
              />
              {errors.old_password && touched.old_password && (
                <p className="error">{errors.old_password}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="new_password">
                <i className="fa-solid fa-lock"></i>كلمة المرور الجديدة
              </label>
              <input
                value={values.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.new_password && touched.new_password
                    ? "inputError"
                    : ""
                }`}
                type="password"
                name="new_password"
                id="new_password"
              />
              {errors.new_password && touched.new_password && (
                <p className="error">{errors.new_password}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="confirm_new_password">
                <i className="fa-solid fa-lock"></i>أعد ادخال كلمة المرور
                الجديدة
              </label>
              <input
                value={values.confirm_new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.confirm_new_password && touched.confirm_new_password
                    ? "inputError"
                    : ""
                }`}
                type="password"
                name="confirm_new_password"
                id="confirm_new_password"
              />
              {errors.confirm_new_password && touched.confirm_new_password && (
                <p className="error">{errors.confirm_new_password}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
