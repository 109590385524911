import React, { useContext } from 'react';
import './Home.scss';
import { Context } from '../Store';
import Menu from '../Menu/Menu';
import ChangeTitles from './ChangeTitles/ChangeTitles';


const Home = () => {
    let{menu,editTitles,setEditTitles,changeTitlesResMessage,changeTitlesRes} = useContext(Context);
  return (
    <>
    <div className='serverMessage'>
    {changeTitlesRes.success===false?<p className='alert-danger'>{changeTitlesRes.message}</p>:""}
    {changeTitlesRes.success &&changeTitlesResMessage?<p className='alert-success'>تم التعديل بنجاح</p>:""}
    </div>
        <section id='home'>
            <Menu/>
            <div className="containers">
              <div className="change">
              <button className='btnChange' onClick={() => setEditTitles(true)}>تغيير العناوين</button>
              </div>
              <div className="items">
                <div className="item">
                  <h2>نبذه عنا</h2>
                  <p>{menu.about_us_ar}</p>
                </div>
                <div className="item">
                  <h2>الرؤية</h2>
                  <p>{menu.vision_ar}</p>
                </div>
                <div className="item">
                  <h2>الرسالة</h2>
                  <p>{menu.message_ar}</p>
                </div>
              </div>
            </div>
        </section>
    {editTitles?<ChangeTitles/>:""}
    </>
  )
}

export default Home