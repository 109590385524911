import React, { useContext, useState } from "react";
import "./Works.scss";
import { Context } from "../Store";
import Menu from "../Menu/Menu";
import axios from "axios";
import AddWork from "./AddWork/AddWork";
import { toast } from "react-toastify";
import EditWork from "./EditWork/EditWork";

const Works = () => {
  let {
    baseURL,
    works,
    editWork,
    setEditWork,
    addWork,
    setAddWork,
    addWorkRes,
    addWorkResMessage,
    getWorks,
    setWorks,
    currentWork,
    setCurrentWork,
  } = useContext(Context);
  let [deleteResMessage, setDeleteResMessage] = useState(false);
  let [deleteWorkRes, setDeleteWorkRes] = useState("");
  let [loading, setLoading] = useState(false);
  const deleteWork = async (id) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/work-delete`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setDeleteWorkRes(data);
      setCurrentWork(id);
      if (data.success) {
        setDeleteResMessage(true);
        getWorks(setWorks);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
        setLoading(false);
      } else {
        setLoading(false);
        setDeleteResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDeleteResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  ///////////////
  const deleteWorkArea = async (id) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${baseURL}/delete-work-area`,
        {
          id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      // setCurrentWork(id);
      if (data.success) {
        getWorks(setWorks);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const getWorkId = (id) => {
    setEditWork(true);
    setCurrentWork(id);
  };

  return (
    <>
      <div className="serverMessage">
        {deleteWorkRes.success === false && deleteResMessage ? (
          <p className="alert-danger">حدث خطأ اثناء الحذف</p>
        ) : (
          ""
        )}
        {deleteWorkRes.success && deleteResMessage ? (
          <p className="alert-success">تم الحذف بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <div className="serverMessage">
        {addWorkRes.success === false && addWorkResMessage ? (
          <p className="alert-danger">{addWorkRes.message}</p>
        ) : (
          ""
        )}
        {addWorkRes.success && addWorkResMessage ? (
          <p className="alert-success">تم اضافة عمل بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="works">
        <Menu />
        <div className="containers">
          <div className="change">
            <button
              type="button"
              className="btnChange"
              onClick={() => setAddWork(true)}
            >
              أضافة عمل
            </button>
          </div>
          <div className="items">
            {works.map((work) => (
              <div className="item" key={work.id}>
                <div className="summary">
                  <span>{work.number}+</span>
                  <p className="titleWork">{work.name_ar}</p>
                </div>
                {work.areas.map((item) => (
                  <div className="areas " key={item.id}>
                    <div className="area">
                      <p>
                        <i className="fa-solid fa-location-dot"></i>
                        {item.title}
                      </p>
                    </div>
                    <div
                      className="delete-area"
                      onClick={() => deleteWorkArea(item.id)}
                    >
                      {loading && currentWork === item.id ? (
                        <i className="fas fa-spinner fa-spin"></i>
                      ) : (
                        <i className="fa-solid fa-trash"></i>
                      )}
                    </div>
                  </div>
                ))}
                <div className="delete" onClick={() => deleteWork(work.id)}>
                  {loading && currentWork === work.id ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fa-solid fa-trash"></i>
                  )}
                </div>
                <button className="edit" onClick={() => getWorkId(work)}>
                  تعديل
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
      {addWork && <AddWork />}
      {editWork && <EditWork />}
    </>
  );
};
export default Works;
