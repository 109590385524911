import React, { useEffect } from "react";
import styles from "./Notfound.module.scss";

const Notfound = () => {
  useEffect(() => {
    document.title = "هذه الصفحة غير موجودة - المهرة";
  }, []);
  return (
    <>
      <div id={styles.notFound}>
        <h2>الصفحة غير موجودة</h2>
        <p> لم نتكمن من العثور على ما كنت تبحث عنه </p>
      </div>
    </>
  );
};

export default Notfound;
