import axios from "axios";
import React, { useState, useContext } from "react";
import { profileSchema } from "./ProfileSchema";
import { useFormik } from "formik";
import Menu from "../Menu/Menu";
import "./Profile.scss";
import { Context } from "../Store";
import { toast } from "react-toastify";

const Profile = () => {
  let { baseURL, profile } = useContext(Context);
  let [loading, setLoading] = useState(false);
  let [changeProfileRes, setChangeProfileRes] = useState("");
  let [changeProfileResMessage, setChangeProfileResMessage] = useState(false);
  const onSubmit = async (values) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("image", values.image);
    try {
      let { data } = await axios.post(
        `${baseURL}/update-admin-profile`,
        formData,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            lang: "ar",
          },
        }
      );
      setChangeProfileRes(data);
      if (data.success) {
        setLoading(false);
        setChangeProfileResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeProfileResMessage(false);
      } else {
        setLoading(false);
        setChangeProfileResMessage(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setChangeProfileResMessage(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.message ||
          error?.response?.date?.message ||
          "Something went wrong",
        {
          position: "top-right",
        }
      );
    }
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      image: profile.image,
    },
    validationSchema: profileSchema,
    onSubmit,
  });

  return (
    <>
      <div className="serverMessage">
        {changeProfileRes.success === false ? (
          <p className="alert-danger">{changeProfileRes.message}</p>
        ) : (
          ""
        )}
        {changeProfileRes.success && changeProfileResMessage ? (
          <p className="alert-success">تم التعديل بنجاح</p>
        ) : (
          ""
        )}
      </div>
      <section id="profile">
        <Menu />
        <div className="containers">
          <div className="head">
            <h3>بيانات المستخدم</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input">
              <label htmlFor="name">
                <i className="fa-solid fa-user"></i>الاسم
              </label>
              <input
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.name && touched.name ? "inputError" : ""}`}
                type="text"
                name="name"
                id="name"
              />
              {errors.name && touched.name && (
                <p className="error">{errors.name}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="email">
                <i className="fa-solid fa-envelope"></i>البريد الالكتروني
              </label>
              <input
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.email && touched.email ? "inputError" : ""
                }`}
                type="email"
                name="email"
                id="email"
              />
              {errors.email && touched.email && (
                <p className="error">{errors.email}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="phone">
                <i className="fa-solid fa-phone"></i>رقم الهاتف
              </label>
              <input
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${
                  errors.phone && touched.phone ? "inputError" : ""
                }`}
                type="number"
                name="phone"
                id="phone"
              />
              {errors.phone && touched.phone && (
                <p className="error">{errors.phone}</p>
              )}
            </div>
            <div className="input">
              <label htmlFor="image">
                <i className="fa-solid fa-image"></i>الصورة
              </label>
              {/* {values.image&&<PreviewImage image={values.image} />}  */}
              {/* img=null */}
              <input
                onChange={(e) => {
                  setFieldValue("image", e.target.files[0]);
                }}
                onBlur={handleBlur}
                className={`${
                  errors.image && touched.image ? "inputError" : ""
                }`}
                type="file"
                name="image"
                id="image"
              />

              {errors.image && touched.image && (
                <p className="error">{errors.image}</p>
              )}
            </div>
            <button
              disabled={isSubmitting || loading}
              type="submit"
              className="loginBtn"
            >
              {loading ? <i className="fas fa-spinner fa-spin"></i> : "حفظ"}
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Profile;
