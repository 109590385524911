import * as yup from "yup";
// const passwordRules = /^(?=.*\d) (?=.*[a-z]) (?=.*[A-Z]).{5,}$/;
// min 5 Characters, 1 upper case letter, 1 lower case letter, 1 numeric digit 
export const workSchema = yup.object().shape({
    name_ar: yup.string().required("هذا الحقل مطلوب"),
    number: yup.number("برجاء ادخال ارقام فقط").required("هذا الحقل مطلوب"),
    areas: yup.array()
    .of(
      yup.object().shape({
        title: yup.string().required("هذا الحقل مطلوب"),
      })
    )
//    : yup.string().required("هذا الحقل مطلوب"),

})